import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { useNotification } from "../Notification";
import { useDropzone } from "react-dropzone";
import { saveImage, getImage, removeImage } from "../../utils/indexedDBUtils";

const defaultImageURL = "/images/akcb-light.jpg";

const ImageUploader = ({ uploadedImage, setUploadedImage }) => {
  const { showNotification } = useNotification();
  const [imageUrl, setImageUrl] = useState(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  const MAX_SIZE = 5 * 1024 * 1024; // 5MB

  useEffect(() => {
    const loadSavedImage = async () => {
      try {
        const savedImage = await getImage();
        if (savedImage) {
          setUploadedImage(savedImage);
          setImageUrl(savedImage);
        }
      } catch (error) {
        console.error("Error loading saved image:", error);
        showNotification("Failed to load saved image. Please try uploading again.", "error");
      }
    };
    loadSavedImage();
  }, [setUploadedImage, showNotification]);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      if (file.size > MAX_SIZE) {
        console.error("File is too large");
        showNotification("File is too large. Please upload a smaller image.", "error");
        return;
      }
      setIsLoading(true);
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const result = e.target.result;
          await saveImage(result);
          setUploadedImage(result);
          setImageUrl(result);
          showNotification("Image uploaded successfully", "success");
        } catch (error) {
          console.error("Error saving image:", error);
          showNotification("Failed to save the image. Please try again.", "error");
        } finally {
          setIsLoading(false);
        }
      };
      reader.onerror = (error) => {
        setIsLoading(false);
        console.error("Error reading file:", error);
        showNotification("Failed to read the image file. Please try again.", "error");
      };
      reader.readAsDataURL(file);
    }
  }, [setUploadedImage, showNotification]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/jpeg": [], "image/png": [] },
    maxSize: MAX_SIZE,
    multiple: false,
  });

  const handleClearImage = useCallback(async () => {
    try {
      await removeImage();
      setImageUrl(defaultImageURL);
      setUploadedImage(defaultImageURL);
      showNotification("Image removed successfully", "success");
    } catch (error) {
      console.error("Error removing image:", error);
      showNotification("Failed to remove the image. Please try again.", "error");
    }
  }, [setUploadedImage, showNotification]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        mb: 2,
      }}
    >
      <Typography variant="body1">
        Use the button below to upload your PFP. To achieve the best results,
        use a 1080 x 1080 image.
      </Typography>
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed #cccccc",
          borderRadius: "4px",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
          marginBottom: "10px",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography>Drop the image here ...</Typography>
        ) : (
          <Typography>
            Drag 'n' drop an image here, or click to select an image
          </Typography>
        )}
      </Box>
      {isLoading && <Typography>Loading...</Typography>}
      {imageUrl && (
        <Box mt={2} display="flex" alignItems="center">
          <img
            src={imageUrl}
            alt="Selected"
            style={{
              width: "100px",
              height: "100px",
              objectFit: "cover",
              marginRight: "10px",
              borderRadius: "5px",
              border: "1px solid green",
            }}
          />
          <IconButton onClick={handleClearImage} color="inherit">
            <ClearIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ImageUploader;