const DB_NAME = 'ImageUploaderDB';
const STORE_NAME = 'images';
const DB_VERSION = 1;

const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore(STORE_NAME, { keyPath: 'id' });
    };
  });
};

const performTransaction = async (mode, callback) => {
  try {
    const db = await openDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(STORE_NAME, mode);
      const store = transaction.objectStore(STORE_NAME);
      
      const request = callback(store);
      
      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);
    });
  } catch (error) {
    console.error(`Error in IndexedDB transaction:`, error);
    throw error;
  }
};

export const saveImage = (imageData) => 
  performTransaction('readwrite', (store) => 
    store.put({ id: 'uploadedImage', data: imageData }));

export const getImage = () => 
  performTransaction('readonly', (store) => 
    store.get('uploadedImage')).then(result => result ? result.data : null);

export const removeImage = () => 
  performTransaction('readwrite', (store) => 
    store.delete('uploadedImage'));