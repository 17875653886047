import React, { useCallback, useEffect, useRef, useState } from "react";
import "./App.css";
import Card from "./components/Card";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ModalComponent from "./components/ModalComponent";
import { NotificationProvider } from "./components/Notification";
import Sidebar from "./components/Sidebar/Sidebar";
import "./styles/variables.css";
import { saveImage, getImage, removeImage } from "./utils/indexedDBUtils";
import { 
  getDetailsFromStorage, 
  saveDetailsToStorage,
  getTemplateFromStorage,
  saveTemplateToStorage,
  getSubTemplateFromStorage,
  saveSubTemplateToStorage,
  getColorModeFromStorage,
  saveColorModeToStorage,
  getShowBackgroundFromStorage,
  saveShowBackgroundToStorage
} from "./utils/storageUtils";
import { useTemplates } from "./hooks/useTemplates";
import { useTheme } from "./hooks/useTheme";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

const App = () => {
  const { templates, loading: templatesLoading, error: templatesError } = useTemplates();
  const { darkMode, toggleTheme } = useTheme();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [template, setTemplate] = useState(
    getTemplateFromStorage()
  );
  const [subTemplate, setSubTemplate] = useState(
    getSubTemplateFromStorage()
  );
  const [useCustomQRCode, setUseCustomQRCode] = useState(
    JSON.parse(localStorage.getItem("useCustomQRCode") || "false")
  );
  const [customQRCodeURL, setCustomQRCodeURL] = useState(
    localStorage.getItem("customQRCodeURL") || ""
  );
  const [details, setDetails] = useState(getDetailsFromStorage);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [useTemplateBackground, setUseTemplateBackground] = useState(
    JSON.parse(localStorage.getItem("useTemplateBackground") || "false")
  );
  const [open, setOpen] = useState(true); // State for modal
  const cardRef = useRef(null);
  const [showBackground, setShowBackground] = useState(getShowBackgroundFromStorage);

  useEffect(() => {
    const loadSavedImage = async () => {
      try {
        const savedImage = await getImage();
        if (savedImage) {
          setUploadedImage(savedImage);
        }
      } catch (error) {
        console.error("Error loading saved image:", error);
      }
    };
    loadSavedImage();
  }, []);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prev => !prev);
  }, []);

  useEffect(() => {
    saveDetailsToStorage(details);
    saveTemplateToStorage(template);
    saveSubTemplateToStorage(subTemplate);
    localStorage.setItem("useCustomQRCode", JSON.stringify(useCustomQRCode));
    localStorage.setItem("customQRCodeURL", customQRCodeURL);
    localStorage.setItem("useTemplateBackground", JSON.stringify(useTemplateBackground));
    saveShowBackgroundToStorage(showBackground);
  }, [
    details,
    template,
    subTemplate,
    useCustomQRCode,
    customQRCodeURL,
    useTemplateBackground,
    showBackground,
  ]);

  const handleClose = useCallback(() => setOpen(false), []); // Function to close the modal

  const clearDownloadLink = useCallback(() => {
    if (cardRef.current) {
      cardRef.current.clearDownloadLink();
    }
  }, []);

  if (templatesLoading) {
    return <div>Loading templates...</div>;
  }

  if (templatesError) {
    return <div>Error loading templates: {templatesError.message}</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <NotificationProvider>
        <div className={`app-container ${darkMode ? 'dark-mode' : ''}`}>
          <Header
            toggleSidebar={toggleSidebar}
            toggleTheme={toggleTheme}
            darkMode={darkMode}
          />
          <div className="content-container">
            <Sidebar
              isOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
              setTemplate={setTemplate}
              darkMode={darkMode}
              template={template}
              subTemplate={subTemplate}
              setSubTemplate={setSubTemplate}
              useCustomQRCode={useCustomQRCode}
              setUseCustomQRCode={setUseCustomQRCode}
              customQRCodeURL={customQRCodeURL}
              setCustomQRCodeURL={setCustomQRCodeURL}
              details={details}
              setDetails={setDetails}
              uploadedImage={uploadedImage}
              setUploadedImage={setUploadedImage}
              useTemplateBackground={useTemplateBackground}
              setUseTemplateBackground={setUseTemplateBackground}
              showBackground={showBackground}
              setShowBackground={setShowBackground}
              getStage={() => cardRef.current}
              clearDownloadLink={clearDownloadLink}
              templates={templates}
            />
            <main className="main-content">
              <div className="canvas-container">
                <Card
                  ref={cardRef}
                  details={details}
                  template={templates[template]}
                  subTemplate={subTemplate}
                  useCustomQRCode={useCustomQRCode}
                  customQRCodeURL={customQRCodeURL}
                  uploadedImage={uploadedImage}
                  useTemplateBackground={useTemplateBackground}
                  showBackground={showBackground}
                  darkMode={darkMode}
                />
              </div>
            </main>
          </div>
          <Footer darkMode={darkMode} />
          <ModalComponent
            className="modal"
            darkMode={darkMode}
            open={open}
            handleClose={handleClose}
          />
        </div>
      </NotificationProvider>
    </ThemeProvider>
  );
};

export default App;