import { useState, useEffect } from 'react';

export function useTemplates() {
  const [templates, setTemplates] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchTemplates() {
      try {
        // First, fetch the template list
        const listResponse = await fetch('/templates/template-list.json');
        const templateList = await listResponse.json();

        // Then, fetch each template
        const templateData = {};
        for (const templateName of templateList) {
          const response = await fetch(`/templates/${templateName}.json`);
          templateData[templateName] = await response.json();
        }

        setTemplates(templateData);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    }

    fetchTemplates();
  }, []);

  return { templates, loading, error };
}