import { Box, Button, Typography } from "@mui/material";
import React from "react";

const SaveButton = ({ getStage, showBackground }) => {
  const handleSave = async () => {
    const stage = getStage();
    if (stage && stage.saveImage) {
      try {
        const dataURL = stage.saveImage({
          pixelRatio: 2,
          mimeType: showBackground ? 'image/jpeg' : 'image/png',
          backgroundColor: showBackground ? undefined : 'transparent'
        });
        const blob = dataURLToBlob(dataURL);
        const url = URL.createObjectURL(blob);

        // Trigger download
        const link = document.createElement("a");
        link.href = url;
        link.download = showBackground ? "id-card.jpg" : "id-card.png";
        link.click();
        URL.revokeObjectURL(url); // Clean up the URL object
      } catch (error) {
        console.error("Error during the save process:", error);
      }
    } else {
      console.error("Error saving image: stage or saveImage is not defined");
    }
  };

  const dataURLToBlob = (dataURL) => {
    const binary = atob(dataURL.split(",")[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        mb: 2,
      }}
    >
      <Typography variant="body1">
        Click the button below to save your ID card as a 2160x2160 image.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={handleSave}
      >
        Save
      </Button>
    </Box>
  );
};

export default SaveButton;