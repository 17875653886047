import Konva from "konva";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { Layer, Stage } from "react-konva";

const DEFAULT_IMAGE_URL = "/images/akcb-light.jpg";
const SCENE_WIDTH = 1080;
const SCENE_HEIGHT = 1080;
const CARD_WIDTH = 440;
const CARD_HEIGHT = 720;
const DEFAULT_QR_URL = "";
const FONT_SIZE_SCALE = 1;
const MAX_TRAIT_COLUMNS = 2;
const TRAIT_VERTICAL_PADDING = 3;
const TRAIT_HORIZONTAL_PADDING = 5;
const TRAITS_TOP_MARGIN = 15;
const TRAITS_BOTTOM_MARGIN = 20;

const Card = forwardRef(({ showBackground, ...props }, ref) => {
  const {
    details,
    template,
    subTemplate,
    uploadedImage,
    useTemplateBackground,
    useCustomQRCode,
    customQRCodeURL,
  } = props;

  const stageRef = useRef(null);
  const containerRef = useRef(null);
  const backgroundLayerRef = useRef(null);
  const imagesLayerRef = useRef(null);
  const textLayerRef = useRef(null);
  const qrCodeLayerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getStage: () => stageRef.current,
    saveImage: saveFullResolutionImage,
  }));

  const fitStageIntoParentContainer = () => {
    const container = containerRef.current;
    if (container) {
      const containerWidth = container.offsetWidth;
      const scale = containerWidth / SCENE_WIDTH;

      stageRef.current.width(SCENE_WIDTH * scale);
      stageRef.current.height(SCENE_HEIGHT * scale);
      stageRef.current.scale({ x: scale, y: scale });
      stageRef.current.batchDraw();
    }
  };

  useEffect(() => {
    fitStageIntoParentContainer();
    window.addEventListener("resize", fitStageIntoParentContainer);
    return () => {
      window.removeEventListener("resize", fitStageIntoParentContainer);
    };
  }, []);

  const saveFullResolutionImage = () => {
    const stage = stageRef.current;
    stage.width(SCENE_WIDTH);
    stage.height(SCENE_HEIGHT);
    stage.scale({ x: 1, y: 1 });
    stage.batchDraw();

    const dataURL = stage.toDataURL({ pixelRatio: 2 });
    fitStageIntoParentContainer();

    return dataURL;
  };

  const templateSettings = useMemo(() => {
    if (!template || !template.variants || !template.variants[subTemplate]) {
      return {};
    }
    return {
      ...template,
      ...template.variants[subTemplate],
    };
  }, [template, subTemplate]);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.src = url;
      img.crossOrigin = "anonymous";
      img.onload = () => resolve(img);
      img.onerror = (error) => {
        console.error(`Failed to load image: ${url}`, error);
        reject(new Error(`Failed to load image: ${url}`));
      };
    });
  };

  const calculateFontSize = useCallback((text, maxWidth, maxHeight, initialFontSize = 24) => {
    const context = document.createElement("canvas").getContext("2d");
    let fontSize = initialFontSize;
    context.font = `${fontSize}px ${templateSettings.fontFamily}`;

    while ((context.measureText(text).width > maxWidth || fontSize > maxHeight) && fontSize > 8) {
      fontSize -= 1;
      context.font = `${fontSize}px ${templateSettings.fontFamily}`;
    }

    return fontSize * FONT_SIZE_SCALE;
  }, [templateSettings.fontFamily]);

  const createTextElement = useCallback((config) => {
    return new Konva.Text({
      ...config,
      fill: templateSettings.textColor,
      fontFamily: templateSettings.fontFamily,
    });
  }, [templateSettings.textColor, templateSettings.fontFamily]);

  const renderBackground = useCallback(async () => {
    const stage = stageRef.current;
    const backgroundLayer = backgroundLayerRef.current;

    if (!stage || !backgroundLayer) return;

    backgroundLayer.destroyChildren();

    if (showBackground) {
      const backgroundImageURL =
        useTemplateBackground && templateSettings.collectionBackground
          ? templateSettings.collectionBackground
          : uploadedImage || DEFAULT_IMAGE_URL;

      try {
        console.log(`Loading background image: ${backgroundImageURL}`);
        const backgroundImage = await loadImage(backgroundImageURL);
        const bg = new Konva.Image({
          image: backgroundImage,
          width: SCENE_WIDTH,
          height: SCENE_HEIGHT,
          x: 0,
          y: 0,
          scaleX: templateSettings.backgroundZoom,
          scaleY: templateSettings.backgroundZoom,
          blurRadius: templateSettings.backgroundBlur,
        });
        bg.cache();
        bg.filters([Konva.Filters.Blur]);
        backgroundLayer.add(bg);

        if (templateSettings.overlayGradient) {
          const { colors, coords } = templateSettings.overlayGradient;
          const gradientFill = backgroundLayer
            .getContext()
            .createLinearGradient(
              coords[0] * stage.width(),
              coords[1] * stage.height(),
              coords[2] * stage.width(),
              coords[3] * stage.height()
            );
          colors.forEach((color, index) => {
            gradientFill.addColorStop(index / (colors.length - 1), color);
          });

          const overlayRect = new Konva.Rect({
            width: SCENE_WIDTH,
            height: SCENE_HEIGHT,
            fill: gradientFill,
          });
          backgroundLayer.add(overlayRect);
        }
      } catch (error) {
        console.error("Error in renderBackground:", error);
      }
    }

    backgroundLayer.batchDraw();
  }, [
    showBackground,
    useTemplateBackground,
    templateSettings.collectionBackground,
    uploadedImage,
    templateSettings.backgroundZoom,
    templateSettings.backgroundBlur,
    templateSettings.overlayGradient,
  ]);

  const renderCard = useCallback(async () => {
    const stage = stageRef.current;
    const imagesLayer = imagesLayerRef.current;

    if (!stage || !imagesLayer) return;

    imagesLayer.destroyChildren();

    const cardLeft = (SCENE_WIDTH - CARD_WIDTH) / 2;
    const cardTop = (SCENE_HEIGHT - CARD_HEIGHT) / 2;

    const cardGroup = new Konva.Group({
      x: cardLeft,
      y: cardTop,
    });

    if (templateSettings.cardBackgroundImage) {
      const cardBgImage = await loadImage(
        templateSettings.cardBackgroundImage
      );
      const cardBg = new Konva.Image({
        image: cardBgImage,
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        shadowBlur: 30,
        fill: templateSettings.cardBackgroundColor,
        shadowColor: templateSettings.cardFillColor,
        cornerRadius: 15,
      });
      cardGroup.add(cardBg);
    } else {
      const cardBg = new Konva.Rect({
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        fill: templateSettings.cardFillColor,
        shadowBlur: 30,
        shadowColor: templateSettings.cardFillColor,
        cornerRadius: 15,
      });
      cardGroup.add(cardBg);
    }

    imagesLayer.add(cardGroup);

    const profileImage = uploadedImage || DEFAULT_IMAGE_URL;
    console.log(`Loading profile image: ${profileImage}`);
    const profileImg = await loadImage(profileImage);
    const profilePic = new Konva.Image({
      image: profileImg,
      x: cardLeft + 20,
      y: cardTop + 20,
      width: 400,
      height: 400,
      cornerRadius: 10,
    });
    imagesLayer.add(profilePic);

    const collectionLogo = await loadImage(templateSettings.collectionLogo);
    const logoImg = new Konva.Image({
      image: collectionLogo,
      x: cardLeft + 30,
      y: cardTop + 30,
      width: 60,
      height: 60,
    });
    imagesLayer.add(logoImg);
  }, [
    templateSettings.cardBackgroundImage,
    templateSettings.cardBackgroundColor,
    templateSettings.cardFillColor,
    uploadedImage,
    templateSettings.collectionLogo,
  ]);

  const renderTraits = useCallback(() => {
    const stage = stageRef.current;
    const textLayer = textLayerRef.current;

    if (!stage || !textLayer) return;

    textLayer.destroyChildren();

    const cardLeft = (SCENE_WIDTH - CARD_WIDTH) / 2;
    const cardTop = (SCENE_HEIGHT - CARD_HEIGHT) / 2;

    // Render card title and ID
    const titleFontSize = calculateFontSize(templateSettings.name, CARD_WIDTH - 60, 30, 24);
    textLayer.add(createTextElement({
      text: templateSettings.name,
      x: cardLeft + 30,
      y: cardTop + CARD_HEIGHT - 280,
      fontSize: 16,
      fontStyle: "bold",
    }));

    const idNumberLabel = createTextElement({
      text: templateSettings.idPrefix,
      x: cardLeft + 30,
      y: cardTop + CARD_HEIGHT - 260,
      fontSize: 18,
      fontStyle: "bold",
    });
    textLayer.add(idNumberLabel);

    const idNumberFontSize = calculateFontSize(details.idNumber, CARD_WIDTH - idNumberLabel.width() - 35, 30, 24);
    textLayer.add(createTextElement({
      text: details.idNumber,
      x: idNumberLabel.x() + idNumberLabel.width() + 5,
      y: idNumberLabel.y() - 5,
      fontSize: idNumberFontSize + 2,
      fontStyle: "italic bold",
    }));

    // Render owner name
    const ownerNameLabel = createTextElement({
      text: "OWNED BY:",
      x: cardLeft + 30,
      y: cardTop + CARD_HEIGHT - 238,
      fontSize: 12,
      fontStyle: "bold",
    });
    textLayer.add(ownerNameLabel);

    const ownerNameFontSize = calculateFontSize(details.ownerName, CARD_WIDTH - 65, 30, 20);
    textLayer.add(createTextElement({
      text: details.ownerName,
      x: ownerNameLabel.x() + ownerNameLabel.width() + 5,
      y: ownerNameLabel.y() - 5,
      fontSize: ownerNameFontSize,
      fontStyle: "italic bold",
    }));

    // Render traits
    const traitsLabelY = cardTop + CARD_HEIGHT - 205;
    textLayer.add(createTextElement({
      text: templateSettings.traitsLabel,
      x: cardLeft + 30,
      y: traitsLabelY,
      fontSize: 12,
      fontStyle: "bold",
    }));

    const separator = new Konva.Line({
      points: [cardLeft + 30, traitsLabelY + 15, cardLeft + CARD_WIDTH - 30, traitsLabelY + 15],
      stroke: templateSettings.textColor,
      strokeWidth: 1,
    });
    textLayer.add(separator);

    const traits = Object.entries(details.traits)
      .filter(([key, value]) => templateSettings.traits[key] && value)
      .map(([key, value]) => ({
        name: templateSettings.traits[key].name,
        value: Array.isArray(value) ? value.join(", ") : value,
      }));

    const totalTraits = traits.length;
    const columns = Math.min(totalTraits, MAX_TRAIT_COLUMNS);
    const traitWidth = (CARD_WIDTH - 30 - (columns - 1) * TRAIT_HORIZONTAL_PADDING) / columns;
    const availableHeight = CARD_HEIGHT - (traitsLabelY + TRAITS_TOP_MARGIN + TRAITS_BOTTOM_MARGIN - cardTop);
    
    // Calculate the optimal number of rows
    const optimalRows = Math.ceil(totalTraits / columns);
    const traitHeight = Math.min(
      (availableHeight - (optimalRows - 1) * TRAIT_VERTICAL_PADDING) / optimalRows,
      35 // Maximum height for a trait
    );

    traits.forEach((trait, index) => {
      const column = Math.floor(index / optimalRows);
      const row = index % optimalRows;
      const traitX = cardLeft + 30 + column * (traitWidth + TRAIT_HORIZONTAL_PADDING);
      const traitY = traitsLabelY + 10 + TRAITS_TOP_MARGIN + row * (traitHeight + TRAIT_VERTICAL_PADDING);

      const traitNameFontSize = calculateFontSize(trait.name, traitWidth, traitHeight / 3, 12);
      const traitName = createTextElement({
        text: trait.name,
        x: traitX,
        y: traitY,
        fontSize: traitNameFontSize,
        width: traitWidth,
      });
      textLayer.add(traitName);

      const traitValueFontSize = calculateFontSize(trait.value, traitWidth, traitHeight * 2 / 3, 16);
      textLayer.add(createTextElement({
        text: trait.value,
        x: traitX,
        y: traitY + traitName.height() + 2,
        fontSize: traitValueFontSize,
        fontStyle: "bold italic",
        width: traitWidth,
      }));
    });
  }, [details, templateSettings, calculateFontSize, createTextElement]);

  const renderQRCode = useCallback(async () => {
    const stage = stageRef.current;
    const qrCodeLayer = qrCodeLayerRef.current;

    if (!stage || !qrCodeLayer) return;

    qrCodeLayer.destroyChildren();

    const cardLeft = (SCENE_WIDTH - CARD_WIDTH) / 2;
    const cardTop = (SCENE_HEIGHT - CARD_HEIGHT) / 2;

    const qrCodeURL = useCustomQRCode
      ? customQRCodeURL || DEFAULT_QR_URL
      : templateSettings.qrCode;

    if (qrCodeURL && qrCodeURL.trim() !== "") {
      try {
        const qrCodeImage = await loadImage(
          `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
            qrCodeURL
          )}&size=100x100`
        );
        const qrCode = new Konva.Image({
          image: qrCodeImage,
          x: cardLeft + CARD_WIDTH - 100,
          y: cardTop + CARD_HEIGHT - 280,
          width: 70,
          height: 70,
        });
        qrCodeLayer.add(qrCode);
      } catch (error) {
        console.error("Failed to load QR code image:", error);
      }
    }
    
    qrCodeLayer.batchDraw();
  }, [
    useCustomQRCode,
    customQRCodeURL,
    templateSettings.qrCode,
  ]);

  const renderCanvas = useCallback(async () => {
    try {
      await renderBackground();
      await renderCard();
      renderTraits();
      await renderQRCode();
      if (stageRef.current) {
        stageRef.current.batchDraw();
      }
    } catch (error) {
      console.error("Error rendering canvas:", error);
    }
  }, [
    renderBackground,
    renderCard,
    renderTraits,
    renderQRCode,
  ]);

  useEffect(() => {
    renderCanvas();
  }, [
    useTemplateBackground,
    uploadedImage,
    details,
    template,
    subTemplate,
    useCustomQRCode,
    customQRCodeURL,
    renderCanvas,
  ]);

  return (
    <div
      id="stage-parent"
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stage width={SCENE_WIDTH} height={SCENE_HEIGHT} ref={stageRef}>
        <Layer ref={backgroundLayerRef} />
        <Layer ref={imagesLayerRef} />
        <Layer ref={textLayerRef} />
        <Layer ref={qrCodeLayerRef} />
      </Stage>
    </div>
  );
});

export default Card;
