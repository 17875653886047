export const initialCardDetails = {
  idNumber: "0000",
  ownerName: "Your Name Here",
  traits: {
    trait1: "Value 1",
    trait2: "Value 2",
  },
  additionalTraits: {},
};

export const DEFAULT_TEMPLATE = "AKCB";
export const DEFAULT_SUB_TEMPLATE = "dark";
export const DEFAULT_COLOR_MODE = "dark";

const setItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error saving ${key} to localStorage:`, error);
  }
};

const getItem = (key, defaultValue) => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  } catch (error) {
    console.error(`Error getting ${key} from localStorage:`, error);
    return defaultValue;
  }
};

export const saveDetailsToStorage = (details) => setItem("details", details);
export const getDetailsFromStorage = () => getItem("details", initialCardDetails);

export const saveTemplateToStorage = (template) => setItem("template", template);
export const getTemplateFromStorage = () => getItem("template", DEFAULT_TEMPLATE);

export const saveSubTemplateToStorage = (subTemplate) => setItem("subTemplate", subTemplate);
export const getSubTemplateFromStorage = () => getItem("subTemplate", DEFAULT_SUB_TEMPLATE);

export const saveColorModeToStorage = (colorMode) => setItem("colorMode", colorMode);
export const getColorModeFromStorage = () => getItem("colorMode", DEFAULT_COLOR_MODE);

export const saveShowBackgroundToStorage = (showBackground) => setItem("showBackground", showBackground);
export const getShowBackgroundFromStorage = () => getItem("showBackground", true);

export const saveUseTemplateBackgroundToStorage = (useTemplateBackground) => setItem("useTemplateBackground", useTemplateBackground);
export const getUseTemplateBackgroundFromStorage = () => getItem("useTemplateBackground", true);

export const saveUseCustomQRCodeToStorage = (useCustomQRCode) => setItem("useCustomQRCode", useCustomQRCode);
export const getUseCustomQRCodeFromStorage = () => getItem("useCustomQRCode", false);

export const saveCustomQRCodeURLToStorage = (customQRCodeURL) => setItem("customQRCodeURL", customQRCodeURL);
export const getCustomQRCodeURLFromStorage = () => getItem("customQRCodeURL", "");