import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--header-background-color)',
          color: 'var(--header-text-color)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: 'var(--sidebar-background-color)',
          color: 'var(--sidebar-text-color)',
        },
      },
    },
  },
});

export default theme;