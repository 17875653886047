import Close from "@mui/icons-material/Close";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useState, useCallback } from "react";
import "../../styles/Sidebar.css";
import CardDetails from "./CardDetails";
import ImageUploader from "./ImageUploader";
import QRCodeGenerator from "./QRCodeGenerator";
import SaveButton from "./SaveButton";
import TemplateSelector from "./TemplateSelector";

const Sidebar = ({
  isOpen,
  toggleSidebar,
  setTemplate,
  setTemplateMode,
  templateMode,
  template,
  subTemplate,
  setSubTemplate,
  useCustomQRCode,
  setUseCustomQRCode,
  customQRCodeURL,
  setCustomQRCodeURL,
  details,
  setDetails,
  uploadedImage,
  setUploadedImage,
  useTemplateBackground,
  setUseTemplateBackground,
  getStage,
  clearDownloadLink,
  showBackground,
  setShowBackground,
  templates, // Added this prop
}) => {
  const [openSections, setOpenSections] = useState({
    imageUploader: false,
    templateSelector: false,
    cardDetails: false,
    qrCodeGenerator: false,
    saveButton: false,
  });

  const handleSectionClick = useCallback((section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  }, []);

  const renderSection = useCallback((title, key, component) => (
    <>
      <ListItem button onClick={() => handleSectionClick(key)}>
        <ListItemText primary={title} className="itemText" />
        {openSections[key] ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openSections[key]} timeout="auto" unmountOnExit>
        <Box className="center-content" p={2}>
          {component}
        </Box>
      </Collapse>
    </>
  ), [openSections, handleSectionClick]);

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={toggleSidebar}
      PaperProps={{ className: "sidebar-drawer" }}
    >
      <Box display="flex" justifyContent="flex-end" p={1}>
        <IconButton onClick={toggleSidebar}>
          <Close />
        </IconButton>
      </Box>
      <List>
        {renderSection("1. Upload Image", "imageUploader", 
          <ImageUploader
            uploadedImage={uploadedImage}
            setUploadedImage={setUploadedImage}
          />
        )}
        {renderSection("2. Select Template", "templateSelector", 
          <TemplateSelector
            setTemplate={setTemplate}
            setTemplateMode={setTemplateMode}
            templateMode={templateMode}
            useTemplateBackground={useTemplateBackground}
            setUseTemplateBackground={setUseTemplateBackground}
            template={template}
            subTemplate={subTemplate}
            setSubTemplate={setSubTemplate}
            templates={templates} // Pass templates to TemplateSelector
            showBackground={showBackground}
            setShowBackground={setShowBackground}
          />
        )}
        {renderSection("3. Enter Info", "cardDetails", 
          <CardDetails
            template={templates[template]} // Pass the selected template
            setDetails={setDetails}
            details={details}
          />
        )}
        {renderSection("4. QR Code (optional)", "qrCodeGenerator", 
          <QRCodeGenerator
            useCustomQRCode={useCustomQRCode}
            setUseCustomQRCode={setUseCustomQRCode}
            customQRCodeURL={customQRCodeURL}
            setCustomQRCodeURL={setCustomQRCodeURL}
          />
        )}
        {renderSection("5. Save", "saveButton", 
          <SaveButton
            getStage={getStage}
            clearDownloadLink={clearDownloadLink}
          />
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;