import React, { useCallback } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  styled
} from "@mui/material";
import { useTemplates } from '../../hooks/useTemplates';

// Styled components for the checkboxes
const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  '& .MuiFormControlLabel-label': {
    fontSize: '0.9rem',
  },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main,
  '&.Mui-checked': {
    color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.dark,
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.text.primary,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&.Mui-focused': {
    color: theme.palette.primary.main,
  },
}));

const TemplateSelector = ({
  setTemplate,
  useTemplateBackground,
  setUseTemplateBackground,
  template,
  subTemplate,
  setSubTemplate,
  templates,
  showBackground,
  setShowBackground,
}) => {
  const { loading, error } = useTemplates();

  const handleTemplateChange = useCallback((event) => {
    const newTemplate = event.target.value;
    setTemplate(newTemplate);
    // Set the first variant as default
    const firstVariant = Object.keys(templates[newTemplate].variants)[0];
    setSubTemplate(firstVariant);
    localStorage.setItem("template", newTemplate);
    localStorage.setItem("subTemplate", firstVariant);
  }, [setTemplate, setSubTemplate, templates]);

  const handleSubTemplateChange = useCallback((event) => {
    const newSubTemplate = event.target.value;
    setSubTemplate(newSubTemplate);
    localStorage.setItem("subTemplate", newSubTemplate);
  }, [setSubTemplate]);

  const handleBackgroundToggle = useCallback(() => {
    setUseTemplateBackground((prev) => {
      const newValue = !prev;
      localStorage.setItem("useTemplateBackground", JSON.stringify(newValue));
      return newValue;
    });
  }, [setUseTemplateBackground]);

  if (loading) return <Typography>Loading templates...</Typography>;
  if (error) return <Typography>Error loading templates: {error.message}</Typography>;

  const currentTemplate = templates[template];
  const variants = currentTemplate ? Object.keys(currentTemplate.variants) : [];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", mb: 2 }}>
      <Typography variant="body2" sx={{ mb: 2 }}>
        Select a project template and then choose a color mode to customize your ID card further.
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <StyledInputLabel id="template-select-label">Template</StyledInputLabel>
        <StyledSelect
          labelId="template-select-label"
          value={template}
          onChange={handleTemplateChange}
          label="Template"
        >
          {Object.entries(templates).map(([templateKey, templateData]) => (
            <MenuItem key={templateKey} value={templateKey}>
              {templateData.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <StyledInputLabel id="sub-template-select-label">Variant</StyledInputLabel>
        <StyledSelect
          labelId="sub-template-select-label"
          value={subTemplate}
          onChange={handleSubTemplateChange}
          label="Variant"
        >
          {variants.map((variantName) => (
            <MenuItem key={variantName} value={variantName}>
              {currentTemplate.variants[variantName].templateName || variantName}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <StyledFormControlLabel
          control={<StyledCheckbox checked={useTemplateBackground} onChange={handleBackgroundToggle} />}
          label="Use Template Background"
        />
        <StyledFormControlLabel
          control={<StyledCheckbox checked={showBackground} onChange={(e) => setShowBackground(e.target.checked)} />}
          label="Show Background"
        />
      </Box>
    </Box>
  );
};

export default TemplateSelector;